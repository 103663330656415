html,body,#root{
    min-height: 100%;
}
body {
    text-align: center;
}
.MainHome {
    text-align: center;
}
.selectHeader {
flexDirection: 'row';
justifyContent: 'center';
alignItems: 'center';
backgroundColor: 'white';
borderColor: '#fff';
alignSelf: 'center';
shadowColor: '#000';
}
/*숫자입력시 위아래버튼없애기*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiContainer-root{padding: 0 !important;}
/*스위치 컬러변경*/
.MuiSwitch-root>.MuiSwitch-colorPrimary.Mui-checked{color: rgb(48,58,83)}
.MuiSwitch-root>.MuiSwitch-track{background: rgb(204,204,204); opacity: 1}
.MuiSwitch-root>.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{background: rgb(48,58,83);}
/* 전체회원 검색*/
.allUser .MuiFormControl-root>.MuiOutlinedInput-root{border-radius: 40px !important;}
.allUser .MuiFormControl-root>.MuiOutlinedInput-root>.MuiSelect-select:focus{border-radius: 40px !important;}
/* 탭메뉴 */
.MuiTabs-root .PrivateTabIndicator-colorPrimary-8{background-color: rgb(48,58,83)}
.MuiTabs-root .MuiTab-textColorPrimary.Mui-selected{color: rgb(48,58,83)}
@media (min-width: 600px){
    .MuiTabs-flexContainer .MuiTab-root {
        min-width: 33.333%;
    }
}
/* 탭메뉴 */
.MnNoticeBoard .MuiTabs-root .PrivateTabIndicator-colorPrimary-8{background-color: rgb(43,66,124)}
.MnNoticeBoard .MuiTabs-root .MuiTab-textColorPrimary.Mui-selected{color: rgb(43,66,124)}
@media (min-width: 600px){
    .MnNoticeBoard .MuiTabs-flexContainer .MuiTab-root {
        min-width: 33.333%;
    }
}

/* 회원가입 체크박스 컬러 */
.MuiFormControlLabel-root>.MuiCheckbox-colorSecondary.Mui-checked{ color: rgb(48,58,83)}
/* 관리가 가입호원 체크박스 컬러 */
.managerCheckbox .MuiFormControlLabel-root>.MuiCheckbox-colorSecondary.Mui-checked{color: rgb(43,66,124)}


@media (max-width: 1260px){
    .MuiContainer-root .makeStyles-downInfo-10 {
        position: initial;
        transform: translateX(0)
    }
}
.mainContent::-webkit-scrollbar { display: none; }
@media (min-width: 600px){
.contentArea.MuiGrid-grid-sm-6 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100% !important;

}
    .MuiGrid-grid-sm-5 {
        flex: auto;
        flex-basis: 100% !important;
    }
}
@media (max-width: 885px) {
    div.makeStyles-footerImg-3{width: 100%;}
    div.makeStyles-footerImg-3>img{width: 20% !important;}
    .MuiGrid-container>div>div.makeStyles-footerText-4{border-left: 0; width: 100%}
    div.makeStyles-footerText-4>p{margin-bottom: 5px}
}

#iframe{width: 450px}
